import React, { useState, useEffect } from 'react';
import api from '../../helper/api';
import formatDate from '../../helper/date';
import '../../styles/table-paginate.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function AdminDeliveryReport() {
  const [deliveries, setDeliveries] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [toDate, setToDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveriesPerPage] = useState(25);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('packageId');

  // Add a useEffect hook to set the initial values
  useEffect(() => {
      setFromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
      setToDate(new Date());
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/deliveries', {
          params: {
            startDate: formatDate(fromDate, 'YYYY-MM-DD'),
            endDate: formatDate(toDate, 'YYYY-MM-DD'),
          },
        });
        setDeliveries(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [fromDate, toDate]);

  const toggleSortOrder = (column) => {
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortOrder('asc');
      setSortColumn(column);
    }
  };

  const filteredDeliveries = deliveries.filter(delivery => {
    const createdAt = new Date(delivery.createdAt);
    const inDateRange = createdAt >= fromDate && createdAt <= toDate;
    const searchPattern = new RegExp(searchText, 'i');
    const inSearch = searchText === ''
      || searchPattern.test(delivery.packageId)
      || searchPattern.test(delivery.unit)
      || searchPattern.test(delivery.courier)
      || searchPattern.test(delivery.packagetype)
      || searchPattern.test(delivery.tracking)
      || searchPattern.test(delivery.location)
      || searchPattern.test(delivery.conciergeId)
      || searchPattern.test(delivery.pickedupResidentId)
      || searchPattern.test(delivery.pickedupConciergeId);

    return inDateRange && inSearch;
  });

  filteredDeliveries.sort((a, b) => {
    if (sortColumn === 'packageId') {
      if (sortOrder === 'asc') {
        return a.packageId < b.packageId ? -1 : 1;
      } else {
        return a.packageId > b.packageId ? -1 : 1;
      }
    }
    return 0;
  });

  const indexOfLastDelivery = currentPage * deliveriesPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage;
  const currentDeliveries = filteredDeliveries.slice(indexOfFirstDelivery, indexOfLastDelivery);

  const totalPages = Math.ceil(filteredDeliveries.length / deliveriesPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="admin-component-tile">
      <h1>Delivery Report</h1>
      <div className="margin-top">
        <label>
          Search: <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} />
        </label>
      </div>
      <div className="margin-top">
        <div className="date-picker-container">
          <label>
          Delivery Date Start:{' '}
          <DatePicker
              className="date-picker"
              selected={fromDate}
              onChange={date => setFromDate(date)}
              dateFormat="yyyy-MM-dd"
          />
        </label>
        <label>
          Delivery Date End:{' '}
        <DatePicker
            className="date-picker"
            selected={toDate}
            onChange={date => setToDate(date)}
            dateFormat="yyyy-MM-dd"
        />
        </label>
        </div>
      </div>
    <p>Showing {filteredDeliveries.length} records</p>
    <div className="margin-top">
      <table className="table">
        <thead>
          <tr>
            <th onClick={() => toggleSortOrder('packageId')}>
              Package ID {sortColumn === 'packageId' && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
            </th>
            <th>Unit</th>
            <th>Courier</th>
            <th>Type</th>
            <th>Tracking</th>
            <th>Location</th>
            <th>Concierge ID</th>
            <th>Delivery date</th>
            <th>Pickup date</th>
            <th>Pickup Resident Name</th>
            <th>Pickup Concierge Name</th>
          </tr>
        </thead>
        <tbody>
          {currentDeliveries.map(delivery => (
            <tr key={delivery.packageId}>
              <td>{delivery.packageId}</td>
              <td>{delivery.unit}</td>
              <td>{delivery.courier}</td>
              <td>{delivery.packagetype}</td>
              <td className="cell-tracking" title={delivery.tracking}>{delivery.tracking}</td>
              <td>{delivery.location}</td>
              <td>{delivery.conciergeId}</td>
              <td>{formatDate(delivery.createdAt, 'YYYY-MM-DD')}</td>
              <td>{delivery.pickedupAt ? formatDate(delivery.pickedupAt, 'YYYY-MM-DD') : ''}</td>
              <td>{delivery.pickedupResidentId}</td>
              <td>{delivery.pickedupConciergeId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>      
    <div className="pagination-container">
      <div className="pagination">
          <button className="pagination-btn" onClick={() => paginate(1)} disabled={currentPage === 1}>
          &laquo;
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
              key={page}
              onClick={() => paginate(page)}
              className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
          >
              {page}
          </button>
          ))}
          <button className="pagination-btn" onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>
          &raquo;
          </button>
      </div>
    </div>
  </div>
  )
};

export default AdminDeliveryReport;