import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
// import jwt_decode from 'jwt-decode';

function LoginPage() {

  const [errorMessage, setErrorMessage] = useState('');

  const handleGoogleAuth = async (credentialResponse) => {

    // Extract the JWT token from the credentialResponse    
    const token = credentialResponse.credential;

    // removing section that checks for credential match to email list

    // // localStorage.setItem('credential', token);

    // // Decode the JWT token to extract the email address
    // const decoded = jwt_decode(credentialResponse.credential);    
    // const email = decoded.email

    // // Check if the email is in the list of allowed emails
    // const allowedEmails = process.env.REACT_APP_ADMINEMAIL;
    // if (!allowedEmails.includes(email)) {
    //   setErrorMessage('Unauthorized login');
    //   return;
    // }

    const apiURL = process.env.REACT_APP_API_URL;

    //console.log(token);

    try {
        // Send the token to the backend API using axios        
        const response = await axios.post(apiURL + '/auth/login', { token }, {
          withCredentials: true
        });

        // If the login was successful, store the access token in local storage or a cookie
        localStorage.setItem('accessToken', response.data.accessToken);

        // Redirect the user to the admin dashboard
        window.location.href = '/admin';
    } catch (error) {
        setErrorMessage('Unauthorized login');
    }
  };

  return (
    <div className='resident-component-tile'>
      <h1>Admin Login</h1>         
      <GoogleLogin
        onSuccess={handleGoogleAuth}
        onFailure={() => console.log('Google authentication failed')}            
      />
      <div style={{ marginTop: "12px", color: "red" }}>{errorMessage && <p>{errorMessage}</p>}</div> 
    </div>
  );
}

export default LoginPage;