import { useState, useEffect } from 'react';
import api from '../../helper/api';

function ResidentList() {
  const [residents, setResidents] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // this renders the residents list
  useEffect(() => {
    fetchResidents();
  }, []);

  const fetchResidents = async () => {
    try {
      const response = await api.get('/residents');
      setResidents(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleSearchInputChange = (event) => {
      setSearchTerm(event.target.value);  
  };

  const handleRowClick = (resident) => {
    setSelected(resident);
  };

  const handleDeleteClick = () => {
    if (selected) {
      if (window.confirm(`Are you sure you want to delete resident ${selected.unit}?`)) {
        deleteResident(selected.unit);
      }
    }
  };

  const deleteResident = async (unit) => {
    try {
      await api.delete(`/residents/${unit}`);
      fetchResidents();
      setSelected(null);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredResidents = residents.filter((resident) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return (
      resident.unit.toLowerCase().includes(searchTermLowerCase) ||
      resident.email1.toLowerCase().includes(searchTermLowerCase) ||
      resident.email2.toLowerCase().includes(searchTermLowerCase)
    );
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    <>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchInputChange}
      />
      <p>Showing {filteredResidents.length} records</p>
      <table className='table'>
        <thead>
          <tr>
            <th>Unit</th>
            <th>Email 1</th>
            <th>Email 1 Active</th>
            <th>Email 2</th>
            <th>Email 2 Active</th>
            <th>Date registered</th>
          </tr>
        </thead>
        <tbody>
          {filteredResidents.map((resident) => (
            <tr
              key={resident.unit}
              className={resident === selected ? 'highlighted' : ''}
              onClick={() => handleRowClick(resident)}
            >
              <td>{resident.unit}</td>
              <td className="cell-tracking" title={resident.email1}>{resident.email1}</td>
              <td>{resident.email1_active ? 'Yes' : 'No'}</td>
              <td className="cell-tracking" title={resident.email2}>{resident.email2}</td>
              <td>{resident.email2_active ? 'Yes' : 'No'}</td>
              <td>{formatDate(resident.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selected && (
        <button onClick={handleDeleteClick}>Delete Resident</button>
      )}
    </>
  );
}

export default ResidentList;
